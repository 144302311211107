<template lang="pug">
  div.home-page
    div.wrap
      div.welcome
        div.hello.col-5
          p.text-averta-light-32(v-if="$store.getters.user") {{$t('hello')}} {{ $store.getters.user.firstName }},
          p.text-averta-regular-20(id="datetime")
        div.search-wrapper.col-6
          search-bar.search-bar(:placeholder="$t('homesearch')" @search="search" :big="true" v-observe-visibility="visibilityChanged" v-if="!searchOpened")
      div.company-activities(v-if="withGroupBusinessUnit")
        div.middle.group-container
          template(v-if="!businessUnitGroupListLoaded")
            skeleton-group-card.card-group(v-for="index in 5" :key="index")
          template(v-else)
            group-card.group-card(v-for="group in businessUnitGroupList" :key="group.id + '-bu'" :group="group")
      p.text-averta-light-38.latest-activities-title {{$t('latestactivities')}}
      div.company-activities.latest-activities
        latest-activities.middle.col-9
        div.right.col-3.col-spacing-1
          company-info-block(:key="userLanguage")
          user-external-links.padding-top

</template>

<script lang="ts">
import Vue from 'vue';
import AddHomePageGroup from '../components/group/AddHomePageGroup.vue';

export default Vue.extend({
  data() {
    return {
      interval: false,
      onlyGroupActivity: true,
      withGroupBusinessUnit: process.env.VUE_APP_WITH_GROUP_BUSINESS_UNIT === 'true',
      searchBarVisibility: true,
      subscribeCommit: null,
    };
  },

  computed: {
    searchOpened() {
      return this.$store.getters['search/searchOpened'];
    },

    userLanguage() {
      return this.$store.getters.getUserLanguage;
    },
    allResultLoaded() {
      return this.$store.getters['search/allResultLoaded'];
    },
    businessUnitGroupList() {
      return this.$store.getters['group/groupBU/getBusinessUnitGroupListByUser'];
    },
    allGroups() {
      let toReturn = [];
      let allGroups = this.$store.getters['group/groupList/allGroupList'];
      if (allGroups) {
        allGroups.forEach((el) => {
          if (el.extraproperties && el.extraproperties.on_homepage) {
            toReturn.push(el);
            this.$forceUpdate();
          }
        });
      }
      return toReturn.sort(function (a, b) {
        return a.label >= b.label ? 1 : -1;
      });
    },
    allGroupLoaded() {
      return this.$store.getters['group/groupList/loadedAll'];
    },
    businessUnitGroupListLoaded() {
      return this.$store.getters['group/groupBU/getUserBusinessUnitGroupListLoaded'];
    },
  },

  components: {
    LatestActivities: () => import('skillbase-component/src/components/activity/LatestActivities.vue'),
    SearchBar: () => import('skillbase-component/src/components/search-engine/components/SearchBar.vue'),
    GroupCard: () => import('skillbase-component/src/components/group/components/blockGroup/GroupCardHome.vue'),
    CompanyInfoBlock: () => import('skillbase-component/src/components/activity/components/CompanyInfoBlock.vue'),
    UserExternalLinks: () => import('skillbase-component/src/components/external-links/components/UserExternalLinks.vue'),
    SkeletonGroupCard: () => import('./../components/group/SkeletonGroupCard.vue'),
  },

  methods: {
    visibilityChanged(isVisible, entry) {
      this.searchBarVisibility = isVisible;
      this.$store.commit('search/showHeaderSearchBar', !isVisible);
      this.$store.commit('search/showHomeSearchBar', isVisible);
    },
    addNewGroup() {
      this.$eventBus.$emit('ModalLauncher:load', AddHomePageGroup);
    },
    search(query) {
      this.$store.dispatch("clientSearch/searchKAI", query)
    }

  },

  mounted() {
    this.$store.commit('search/showHeaderSearchBar', false);
    this.$store.commit('search/showHomeSearchBar', true);
    this.$store.dispatch('group/groupList/fetchAllGroupList');
    this.$store.dispatch('language/fetchUserLanguage');

    if (this.withGroupBusinessUnit) {
      this.$store.dispatch('group/groupBU/fetchUserGroupListByBusinessUnit');
    }

    let language = this.$store.getters['language/getUserLanguage'];

    let myself = this;

    this.interval = setInterval(function () {
      if (document.getElementById('datetime')) {
        if (language == 'zh-cn') {
          document.getElementById('datetime').innerHTML = myself.$moment().format('MMMM Do LT');
        } else {
          document.getElementById('datetime').innerHTML = myself.$moment().format('Do MMMM LT');
        }
      }
    }, 1000);

    this.$eventBus.$on('setOnlyGroupActivity', (onlyGroupActivity) => {
      this.onlyGroupActivity = onlyGroupActivity;
      this.$forceUpdate();
    });

    this.subscribeCommit = this.$store.subscribe((mutation, state) => {
      if (mutation.type == 'mobile-layout/showSearchEngine') {
        this.$store.commit('search/showHeaderSearchBar', !this.searchBarVisibility);
      }
    });
  },

  beforeDestroy() {
    this.$store.commit('search/showHeaderSearchBar', true);
    this.$store.commit('search/showHomeSearchBar', false);
    clearInterval(this.interval);
    this.subscribeCommit();
  },
});
</script>

<style lang="scss" scoped>
.home-page {
  display: flex;
  justify-content: center;

  .wrap {
    width: 1176px;

    .welcome {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 80px;
      margin-bottom: 105px;

      .hello {
        cursor: default;
        text-align: center;
        margin-bottom: 70px;

        .text-averta-light-32 {
          margin-bottom: 7px;
        }
      }

      .search-wrapper {
        position: relative;

        .search-bar {
          z-index: 301;
        }
      }
    }

    .latest-activities {
      padding-bottom: 98px;
    }

    .company-activities {
      cursor: default;
      display: flex;
      margin-bottom: 98px;
      justify-content: center;

      .card-group {
        margin-bottom: 17px;
        width: 221px;
        margin-right: 17px;
        cursor: pointer;
        height: 147px;

        &:nth-child(5n) {
          margin-right: 0;
        }
      }
    }

    .latest-activities-title {
      margin-bottom: 36px;
    }

    .padding-top {
      margin-top: 18px;
    }

    .group-container {
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      display: flex;
    }
  }

  @media only screen and (max-width: 700px) {
    .company-activities {
      .middle,
      .right {
        width: 100%;
      }
    }

    .card-group,
    .add-group {
      width: calc(100% - 20px);
      margin-left: 0;
    }

    .welcome {
      .search-wrapper {
        width: 90vw;
      }
    }
  }
}
</style>
